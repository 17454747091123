import { useState, useEffect } from 'react';
import { CardTextOnly, ColumnHead, MidCard } from "../../../index";
import { BannerAdvertisement } from "../../../common/Advertisement";

const SixthSection = () => {
  const [ politics, setPolitics ] = useState(null);
  const [ politicsS, setPoliticsS ] = useState(null);
  const [ health, setHealth ] = useState(null);
  const [ healthS, setHealthS ] = useState(null);
  const [ technology, setTechnology ] = useState(null);
  const [ technologyS, setTechnologyS ] = useState(null);

  useEffect(() => {
        fetchData('politics', setPolitics, setPoliticsS);
        fetchData('health', setHealth, setHealthS);
        fetchData('technology', setTechnology, setTechnologyS);
  }, []);

  const fetchData = (cat, setMiddle, setSmall) => {
        fetch(`/api/get/news/${cat}`)
        .then(res => res.json())
        .then(data => {
                var mid = [];
                var small = [];
                var processed = [];
                for (let art of data) {
                        var item = {};
                        item.imageSrc = art?.image?.url;
                        item.text = art.title;
                        item.title = art.title;
                        item.summary = art.summary ? art.summary.replace(/<img [^>]*\/>/,'') : art.description;
                        item.description = art.description ? art.description.replace(/<img [^>]*\/>/,'') : art.summary;
                        item.tag = art.source;
                        item.link = art.link;
                        processed.push(item);
                }
                mid = processed.slice(0,1);
                small = processed.slice(1,6);

                small = small.map((i) => {
                        i.text = i.description; //i.summary ? i.summary : i.description;
                        return i;
                });
                setSmall(small);
                setMiddle(mid);
        })
        .catch(e => console.error);
  }
	
  return (
    <div>
      <div className="flex flex-col flex-wrap md:px-8 md:flex-row">
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"POLITICS"} />
          {politics && politics.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {politicsS && politicsS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"HEALTH"} />
          {health && health.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {healthS && healthS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
        <div className="w-full p-4 sm:w-1/2 md:w-1/3">
          <ColumnHead columnHeadTag={"TECH"} />
          {technology && technology.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
          {technologyS && technologyS.map((text, index) => (
            <CardTextOnly key={index} {...text} />
          ))}
        </div>
      </div>
      <BannerAdvertisement />
    </div>
  );
};

export default SixthSection;
