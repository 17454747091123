import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Share, MidCard, CardTextOnly } from "../../../common/Cards";
import { settings } from "../../../../defaults";

const FirstSection = ({props}) => {
	const [ midCards, setMidCards ]  = useState(null);
	const [ midCards2, setMidCards2]  = useState(null);
	const [ textOnly, setTextOnly ]  = useState(null);
	const [ firstArticle, setFirstArticle ]  = useState(null);
	const params = useParams();

  useEffect(() => {
    fetchData();
    fetchFeedData();
  }, [params]);

const fetchFeedData = () => {
	var style = params?.styleId ? params.styleId : settings?.style;
	var url = '';
	url = encodeURI('/api/get/news');

	fetch(url)
	.then(res => res.json())
	.then(data => {
		var processed = [], text = [];
		for (let art of data) {
			var item = {};
			if(!art.title || !art.description) {
				continue;
			}
			item.imageSrc = art.image.url;
			item.text = art.title;
			item.title = art.title;
			item.summary = art.summary.replace(/<img [^>]*\/>/,'');
			item.description = art.description.replace(/<img [^>]*\/>/,'');
			item.tag = art.source;
			item.link = art.link;
			processed.push(item);
		}
		text = processed.slice(0,20);

		setTextOnly(text);
	})
	.catch(e => console.error);
}

const fetchData = () => {
	var style = params?.styleId ? params.styleId : settings?.style;
	var url = '';
	url = encodeURI('/api/get/shockya.com?style='+style);

	fetch(url)
	.then(res => res.json())
	.then(data => {
		console.debug("DATA", data);
		var first = {};
		var mid = [], mid2 = [];
		var text = [];
		var processed = [];
		for (let art of data) {
			var item = {};
			if(!art.title || !art.description) {
				console.debug("????", art);
				continue;
			}
			item.imageSrc = art.image.url;
			item.text = art.title;
			item.title = art.title;
			item.summary = art.summary.replace(/<img [^>]*\/>/,'');
			item.description = art.description.replace(/<img [^>]*\/>/,'');
			item.tag = art.source;
			item.link = art.link;
			processed.push(item);
		}
		first = processed[0];
		mid = processed.slice(1,6);
		mid2 = processed.slice(6,10);

		setFirstArticle(first);
		setMidCards(mid);
		setMidCards2(mid2);
		//setTextOnly(text);
	})
	.catch(e => console.error);
}

  return (
    <>
      <div className="flex flex-col md:px-8 md:flex-row">
	  {firstArticle && (<div className="w-full p-4 md:w-2/3">
            <div className="max-w-screen-md mx-auto">
              <a href={firstArticle?.link} className="" rel="noreferrer" target="_blank">
              <h1 className="text-3xl font-bold mb-4 text-black text-center decoration-1 hover:underline hover:underline-offset-8 hover:text-gray-500">
	  	{firstArticle?.title}
              </h1>
              <div className="max-w-screen-md mx-auto mb-3">
                <div className="aspect-w-16 aspect-h-9">
                <img src={firstArticle?.imageSrc} className="max-w-screen-xs mx-auto mb-3" style={{width: '100%'}}/>
                </div>
              </div>
	      </a>
		  <Share {...firstArticle} />
              <a href={firstArticle?.link} className="" rel="noreferrer" target="_blank">
              <h4 className="text-xl font-bold mb-2 decoration-1 hover:underline hover:underline-offset-4">
	  {firstArticle.description && ( <div dangerouslySetInnerHTML={{ __html:firstArticle.description }} /> )}
              </h4>
              <ul className="px-5">
                <li className="list-disc">
	  {firstArticle.summary && (
		  <div dangerouslySetInnerHTML={{ __html:firstArticle.summary }} />
	  )}
                </li>
              </ul>
              </a>
            </div>
            <div className="max-w-screen-md mx-auto" style={{display: 'flex', flexWrap: 'wrap'}} >
          {midCards2 && midCards2.map((card, index) => (

            <div className="w-full p-4 md:w-1/2">
              <MidCard key={index} {...card} />
            </div>
          ))}
	  </div>
        </div>)}
        <div className="w-full p-4 md:w-1/3">
          {midCards && midCards.map((card, index) => (
            <MidCard key={index} {...card} />
          ))}
        </div>
        <div className="w-full p-4 md:w-1/3">
          <Link to={"/news-link"} className="relaitve w-305 h-171 group mb-4">
            <div>
                <iframe className="w-full h-full rounded-sm" src="https://www.filmon.com/tv/channel/export?channel_id=4&autoPlay=1" frameborder="0" allowfullscreen> </iframe>
            </div>
          </Link>
          <div>
            <div className="mb-2 mt-2 font-bold text-xl decoration-1 hover:underline hover:underline-offset-4">
              Catch up on today's news
            </div>
            <div>
              {textOnly && textOnly.map((cardText, index) => (
                <CardTextOnly key={index} {...cardText} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default FirstSection;
